'use strict';

var animations = function () {
  var AOS = require('aos');
  AOS.init({
    easing: 'ease-out-cubic',
  });
}

module.exports = animations;
