/* eslint-disable */
import GLightbox from 'glightbox';

var lightbox = function() {
  const lightbox = GLightbox({
    'selector': '.js-thumb',
    'skin': 'clean'
  });
};

module.exports = lightbox;
/* eslint-enable */
