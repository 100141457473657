/* eslint-disable */
const SmoothScroll = require('smooth-scroll');

const scrollspy = function() {
  SmoothScroll('.c-main-nav__link', {
    offset: 0,
    before: function (anchor, toggle) {
      const button = document.getElementsByClassName('js-nav-toggle');
      const menuWrapper = document.querySelector('.c-sliding-nav')
      const html = document.querySelector('html');
      const body = document.querySelector('body');
      button[0].classList.remove("is-active");
      menuWrapper.classList.remove('c-sliding-nav--slidein');
      html.classList.remove('u-no-scroll');
      body.classList.remove('u-no-scroll');
    }
  });
};
module.exports = scrollspy;
/* eslint-enable */
