'use strict';

var animateslides = function () {
  var element = document.getElementsByClassName('js-slides');
  if(element.length) {

    var Flickity = require('flickity');

    var f = new Flickity( '.js-slides', {
      //freeScroll: true,
      cellSelector: '.js-slide',
      cellAlign: 'center',
      prevNextButtons: false,
      pageDots: true,
      autoPlay: 3000,
      wrapAround: true
    });
  }
}

module.exports = animateslides;
