'use strict';

var scrollUp = function () {
  var $ = require('jquery');
  $('#js-up').click(function(){
    $("html, body").animate({ scrollTop: 0 }, "medium");
    return false;
  });
};

module.exports = scrollUp;
