'use strict';

var Cookies = require('js-cookie');

var cookies = function () {

  var cookie = Cookies.get('cookieAgree'),
      cookieAlert = document.querySelector('.c-cookies'),
      cookieAlertButton = document.querySelector('.c-cookies button');

  if(typeof cookieAlert !== 'undefined') {
    cookieAlertButton.onclick = function() {
      Cookies.set('cookieAgree', 'CookieSet', { expires: 365 });
      cookieAlert.classList.remove('c-cookies--on');
      console.log('ustawione');
    }
  }

  if ( "undefined" === typeof cookie ) {
    // show cookie alert
    cookieAlert.classList.add('c-cookies--on');
  } else {
    // hide cookie alert (hidden by default); nothing to do here
  }
};

module.exports = cookies;
