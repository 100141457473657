'use strict';

var mobileNav = function () {
  var menuWrapper = document.querySelector('.c-sliding-nav'),
    html = document.querySelector('html'),
    body = document.querySelector('body'),
    trigger = document.getElementsByClassName('js-nav-toggle'),
    menuItems = document.getElementsByClassName('c-main-nav__link');

    var menuAction = function() {
      this.classList.toggle('is-active');
      menuWrapper.classList.toggle('c-sliding-nav--slidein');
      html.classList.toggle('u-no-scroll');
      body.classList.toggle('u-no-scroll');
    }

    for(var i=0; i < trigger.length; i++) {
      trigger[i].onclick = menuAction;
    }

};

module.exports = mobileNav;
