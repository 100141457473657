'use strict';

var GoogleFonts = function () {
  var WebFont = require('webfontloader');
  WebFont.load({
    google: {
      families: ['Kanit:300,300i,400,600,70:latin-ext']
    }
  });
}
module.exports = GoogleFonts;
