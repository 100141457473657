/*
  Project: PBTenders
  Author: Krzysztof Malina
*/
/*
var $ = require('jquery');
window.jQuery = window.$ = $; // You expose jQ as a global object




var secondaryNav = require('./secondary-nav.js');
var taps = require('./taps.js');
var clickable = require('./clickable.js');

var detectFlexbox = require('./detectflexbox.js');

var animateTestimonials = require('./testimonials.js');


detectFlexbox();

animateTestimonials();

taps();
clickable();

heights();
*/
var externalLinks = require('./external-links.js');
var googleFonts = require('./googlefonts.js');
var mobileNav = require('./mobilenav.js');
var cookies = require('./cookies.js');
var animateSlides = require('./slides.js');
var heights = require('./heights.js');
var up = require('./up-button.js');
var animations = require('./animations.js');
//var hashesclicks = require('./hashesclicks.js');
var scrollspy = require('./scrollspy.js');
var lightbox = require('./lightbox.js');

scrollspy();
googleFonts();
mobileNav();
heights();
cookies();
externalLinks();
animateSlides();
up();
//hashesclicks();
lightbox();
animations();
